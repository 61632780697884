<template>
  <v-container
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <material-wizard
        title="Novo Ajuste de BDGD"
        subtitle="Escolha a entidade a ser ajustada e faça o upload dos arquivos com os novos dados."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content @atualizacao:updated="handleTabContentUpdated" />
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <second-tab-content
            @encoding:selected="handleEncodingSelected"
            @delimiter:updated="handleDelimiterUpdated"
            @backupEntity:changed="handleBackupEntityChanged"
            @deleteData:changed="handleDeleteDataChanged"
            @gerarInserts:changed="handleGerarInsertsChanged"
            @entity:selected="handleEntitySelected"
            @file:uploaded="handleFileUploaded"
          />
        </v-tab-item>
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import AtualizacoesBdgdService from '@/services/AtualizacoesBdgdService';
import routes from '@/store/modules/routes';

export default {
  components: {
    MaterialWizard: () => import('@/components/base/MaterialWizard'),
    FirstTabContent: () =>
      import(
        '@/components/geracao-bdgd/atualizacoes-bdgd/tabs/FirstTabContent.vue'
      ),
    SecondTabContent: () =>
      import(
        '@/components/geracao-bdgd/atualizacoes-bdgd/tabs/SecondTabContent.vue'
      )
  },
  data: () => ({
    tab: 0,
    tabs: ['Informações básicas', 'Upload de arquivos'],
    loading: false,
    atualizacao: {
      descricao: null,
      companyId: null,
      bdgdVersion: null,
      entity: null,
      arquivos: [],
      delimitador: ';',
      encoding: 'UTF-8',
      efetuarBackupEntidade: false,
      apagarLinhas: false,
      gerarInserts: true
    }
  }),
  computed: {
    availableSteps() {
      let steps = [];
      if (this.tab1IsValid) {
        steps.push(0);
        steps.push(1);
      }
      if (this.tab2IsValid) {
        steps.push(2);
      }
      return steps;
    },
    tab1IsValid() {
      const { companyId, bdgdVersion } = this.atualizacao;
      return !!(companyId && bdgdVersion);
    },
    tab2IsValid() {
      const { arquivos, delimitador, encoding, entity } = this.atualizacao;
      return !!(
        this.tab1IsValid &&
        arquivos &&
        arquivos.length > 0 &&
        delimitador &&
        encoding &&
        entity
      );
    },
    disabledBtnFinalizar() {
      if (this.tab === 0) return !this.tab1IsValid;
      if (this.tab === 1) return !this.tab2IsValid;
      return true;
    }
  },
  methods: {
    async next(valid) {
      if (!valid) return;
      if (this.tab === 0 && !this.tab1IsValid) return;
      if (this.tab === 1) {
        if (!this.tab2IsValid) return;
        const sucesso = await this.cadastraNovaAtualizacao();
        if (!sucesso) return;
        this.$router.push({
          name: routes.state.appRoutes['ATUALIZACOES_DE_BDGD']
        });
      } else {
        this.tab++;
      }
    },
    handleTabContentUpdated(event) {
      this.atualizacao = Object.assign(this.atualizacao, event);
    },
    handleFileUploaded(files) {
      this.atualizacao.arquivos = files;
    },
    handleEncodingSelected(event) {
      this.atualizacao.encoding = event;
    },
    handleDelimiterUpdated(event) {
      this.atualizacao.delimitador = event;
    },
    handleBackupEntityChanged(event) {
      this.atualizacao.efetuarBackupEntidade = event;
    },
    handleDeleteDataChanged(event) {
      this.atualizacao.apagarLinhas = event;
    },
    handleGerarInsertsChanged(event) {
      this.atualizacao.gerarInserts = event;
    },
    handleEntitySelected(event) {
      this.atualizacao.entity = event;
    },
    async cadastraNovaAtualizacao() {
      const {
        descricao,
        arquivos,
        encoding,
        delimitador,
        entity,
        bdgdVersion,
        efetuarBackupEntidade,
        apagarLinhas,
        gerarInserts,
        companyId
      } = this.atualizacao;

      if (
        apagarLinhas &&
        !confirm(
          'Tem certeza que deseja apagar as linhas presentes nos arquivos enviados?'
        )
      ) {
        return false;
      }
      let sucesso = false;
      const atualizacao = {
        titulo: entity, // O título na maioria dos casos é irrelevante
        descricao,
        arquivos,
        encoding,
        delimitador,
        entidade: entity,
        bdgd_versao_id: bdgdVersion.id,
        efetuar_backup_entidade: efetuarBackupEntidade,
        apagar_linhas: apagarLinhas,
        gerar_inserts: gerarInserts,
        company_id: companyId
      };

      try {
        this.loading = true;
        sucesso = await AtualizacoesBdgdService.save(atualizacao);
        this.$toast.success('Ajuste de BDGD cadastrado com sucesso.', '', {
          position: 'topRight'
        });
      } catch (e) {
        this.$toast.error(
          'Ocorreu um erro ao cadastrar o ajuste de BDGD.',
          '',
          { position: 'topRight' }
        );
        console.log(e);
      } finally {
        this.loading = false;
      }

      return sucesso;
    }
  }
};
</script>
